import { Content } from '@/types/content';
import { getDomain } from '@/utils/getDomain';
import { getGoogleAnalyticsId } from '@/utils/getGoogleAnalyticsId';
import { getRecommendationMicroservice } from '@/utils/getRecommendationMicroservice';
import { getSite } from '@/utils/getSite';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { logger } from '@/utils/logger';
import { getSeenArticleIds } from '@/utils/seenArticles';
import { waitFor } from '@/utils/waitFor';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useAppState } from './useAppState';

interface ScrollArticle {
  article_id: string;
  type: string;
  model: string;
}

const getScrollArticlesIds = async (): Promise<ScrollArticle[]> => {
  await waitFor(() => Boolean(getSeenArticleIds()?.length), { checkInterval: 200, timeout: 2000 });

  const url = getUrl('/articleRecommendationsv2', getRecommendationMicroservice());
  if (!url) return [];

  const payload = {
    article_id: getSeenArticleIds().map(String),
    site: getSite(),
    cookie_id: getGoogleAnalyticsId() ?? '',
  };

  const response = await http.post(url.href, payload, {
    timeout: 20000,
    validateStatus: null,
  });
  const data = (response?.data?.scroll_articles ?? []) as ScrollArticle[];

  if (!data.length) {
    logger.error('Scroll articles not found!', payload);
    http.post(
      '/api/slackNotifier',
      {
        key: `scroll-articles-not-found-${getSite()}`,
        payload: {
          message: `
            Personalized scroll articles not found for ${getDomain()},
            request status: ${response?.status},
            request response: ${JSON.stringify(response?.data)},
            request payload: ${JSON.stringify(payload)}
          `,
        },
        requestInterval: 4 * 60 * 60 * 1000, // 4 hours
      },
      { timeout: 20000 },
    );
  }
  return data;
};

export const useInfinityScrollArticle = ({
  intersectionTargetRef,
}: {
  intersectionTargetRef: RefObject<HTMLDivElement>;
}) => {
  const [{ device }, appStateDispatcher] = useAppState();
  const [loadedArticles, setLoadedArticles] = useState<Content[]>([]);
  const [scrollArticles, setScrollArticles] = useState<ScrollArticle[]>([]);
  const [isLoading, setLoading] = useState(false);

  const loadNextArticle = useCallback(async () => {
    const url = getUrl('/api/page');
    const articleId = scrollArticles[loadedArticles.length]?.article_id;
    if (!url || !articleId || isLoading) return null;

    setLoading(true);

    const params = new URLSearchParams({ route: `article/${articleId}`, device });
    url.search = params.toString();
    const response = await http.get<Content>(url.href);
    const data = response?.data;

    if (data) {
      data.meta.scrollArticleNumber = scrollArticles.findIndex((a) => a.article_id === articleId) + 1;
      setLoadedArticles((prevLoadedArticles) => [...prevLoadedArticles, data]);
    }

    setLoading(false);

    return data;
  }, [scrollArticles, loadedArticles, device, isLoading]);

  useEffectOnce(() => {
    getScrollArticlesIds().then((data) => {
      appStateDispatcher({ scrollArticlesAmount: data?.length ?? 0 });
      setScrollArticles(data);
    });
  });

  useEffect(() => {
    if (!scrollArticles.length || scrollArticles.length === loadedArticles.length || !intersectionTargetRef.current) {
      appStateDispatcher({ activeInfinityScrollArticle: false });
      return;
    }
    appStateDispatcher({ activeInfinityScrollArticle: true });

    const observer = new IntersectionObserver(([entry]) => {
      if ((entry.isIntersecting || entry.boundingClientRect.y < 0) && !isLoading) {
        loadNextArticle().then((data) => {
          if (!data) {
            observer.disconnect();
            appStateDispatcher({ activeInfinityScrollArticle: false });
          }
        });
      }
    });

    observer.observe(intersectionTargetRef.current as Element);

    return () => observer.disconnect();
  }, [scrollArticles, intersectionTargetRef, isLoading, loadNextArticle, loadedArticles, appStateDispatcher]);

  return useMemo(() => ({ articles: loadedArticles, isLoading }), [loadedArticles, isLoading]);
};
