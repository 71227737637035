import { AdTemplate } from '@/components/AdTemplate';
import { Base } from '@/components/Base';
import { Consent } from '@/components/Consent';
import { PageMeta } from '@/components/PageMeta';
import { SchemaMarkup } from '@/components/SchemaMarkup';
import { Tracking } from '@/components/Tracking';
import { useAppState } from '@/hooks/useAppState';
import { Meta } from '@/types/content';
import { isDeviceInvalid } from '@/utils/isDeviceInvalid';
import { CLOUDFLARE_TTL } from '@/utils/ttl';
import { withCacheControlHeaders } from '@/utils/withCacheControlHeaders';
import { withLockedContentHeaders } from '@/utils/withLockedContentHeaders';
import { withOcelotCacheTagsHeaders } from '@/utils/withOcelotCacheTagsHeaders';
import { withSerializationGuard } from '@/utils/withSerializationGuard';
import { withTestContentHeaders } from '@/utils/withTestContentHeaders';
import { InfinityScrollArticle } from 'base/components/InfinityScrollArticle';
import { InfinityScrollFront } from 'base/components/InfinityScrollFront';
import EmbedLogin from 'lib/AlltIdAuth/index';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { getLabradorPage } from 'lib/labrador/getLabradorPage';
import { getLabradorPageProps, LabradorPageProps } from 'lib/labrador/getLabradorPageProps';
import { handleCommonLabradorPageStatus } from 'lib/labrador/handleCommonLabradorPageStatus';
import { stripSlashes } from 'lib/labrador/utils';
import { renderPostTypeComponent } from 'modules/dynamic/components';
import { GetServerSideProps, NextPage } from 'next';

const Page: NextPage<LabradorPageProps> = ({ pageData, adsData, analyticsData }) => {
  const [{ activeInfinityScrollArticle }] = useAppState();
  const isInfinityScrollFrontEnabled = getOcelotConfig('features.infinityScrollFront.enabled');
  const infinityScrollId = pageData.meta.infinityScrollID;
  const infinityScrollMode = pageData.meta.infinityScrollMode as 'front' | 'article';
  const category = pageData.data.category;

  const isNativeAd = pageData.meta.isNativeAd;
  const isPaidContent = pageData.meta.accessLevel === 'purchase';
  const isInfinityScrollArticleEnabled = getOcelotConfig('features.infinityScrollArticle.enabled');
  const showInfinityScrollArticle = !isNativeAd && !isPaidContent && isInfinityScrollArticleEnabled;
  const showInfinityScrollFront = isInfinityScrollFrontEnabled && !activeInfinityScrollArticle && !isPaidContent;

  return (
    <Base
      content={
        <>
          <Consent />
          <PageMeta pageData={pageData} />
          <AdTemplate pageMeta={pageData?.meta as Meta} adsData={adsData} />
          <Tracking {...analyticsData} />
          <SchemaMarkup pageData={pageData} />
          <EmbedLogin />
          {renderPostTypeComponent(pageData)}
          {showInfinityScrollArticle && <InfinityScrollArticle pageData={pageData} />}
          {showInfinityScrollFront && (
            <InfinityScrollFront
              pageData={pageData}
              sourceRoute={
                infinityScrollMode === 'front' && infinityScrollId
                  ? ['_scroll', 'front', infinityScrollId].join('/')
                  : ['_scroll', 'article', category].join('/')
              }
            />
          )}
          <div id="modal" />
        </>
      }
    />
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { device, params = [] } = context.params as { device: string; params?: string[] };

  if (isDeviceInvalid(device)) {
    return { notFound: true };
  }

  const routeKey = params;
  const data = await getLabradorPage(device, routeKey, context.req.url);

  const commonLabradorResponse = handleCommonLabradorPageStatus(data);
  if (commonLabradorResponse) return commonLabradorResponse;

  const publishedUrl = data?.pageData?.data?.publishedUrl;
  if (stripSlashes(publishedUrl) !== routeKey.join('/')) {
    return { redirect: { destination: publishedUrl, permanent: true } };
  }

  withOcelotCacheTagsHeaders(context, data);
  withLockedContentHeaders(context, data);
  withTestContentHeaders(context, data);
  withCacheControlHeaders(context.res, CLOUDFLARE_TTL);

  return withSerializationGuard({
    props: getLabradorPageProps(data),
  });
};

export default Page;
