import { ScrollArticleLabel } from '@/components/ScrollArticleLabel';
import { Spinner } from '@/components/Spinner';
import { TrackingProps } from '@/components/Tracking';
import { useInfinityScrollArticle } from '@/hooks/useInfinityScrollArticle';
import { Content } from '@/types/content';
import { initAdunitElements } from '@/utils/initAdunitElements';
import { isServer } from '@/utils/isServer';
import { logger } from '@/utils/logger';
import { renderPostTypeComponent } from 'modules/dynamic/components';
import { useRouter } from 'next/router';
import { Fragment, useDeferredValue, useEffect, useRef, useState } from 'react';

const registerPageView = () => {
  window.cts?.send?.pageview?.();
};

const updateTrackingData = (analyticsData: TrackingProps, isScrolledArticle: boolean) => {
  if (isServer()) {
    logger.warn("Update tracking data can't be called on server side");
    return;
  }

  const { allerGaData, ctsData, kilkayaData } = analyticsData;

  const commonData = {
    affiliateBool: window.aller_ga?.containAffiliate,
    isAffiliate: window.aller_ga?.containAffiliate,
    isSecondNative: isScrolledArticle,
  };

  window.aller_ga = {
    ...window.aller_ga,
    ...allerGaData,
    ...commonData,
  };

  window.cts.metadata = {
    ...window.cts.metadata,
    ...ctsData,
    ...commonData,
  };

  window.k5aMeta = {
    ...window.k5aMeta,
    ...kilkayaData,
    cntTag: isScrolledArticle ? ['scrolled_pageview'] : [],
  };
};

export const InfinityScrollArticle = ({ pageData }: { pageData: Content }) => {
  const router = useRouter();
  const [registeredArticleIndexes, setRegisteredArticleIndexes] = useState<number[]>([]);
  const articleMarkerRefs = useRef<HTMLDivElement[]>([]);
  const markerRef = useRef<HTMLDivElement>(null);
  const { articles, isLoading } = useInfinityScrollArticle({
    intersectionTargetRef: markerRef,
  });

  const originalUrl = router.asPath;
  const lastArticle = useDeferredValue(articles[articles.length - 1]);

  useEffect(() => {
    if (!lastArticle) return;
    initAdunitElements(lastArticle.meta.adsUniqueId, lastArticle.meta.isNativeAd, true);
  }, [lastArticle]);

  useEffect(() => {
    if (!articleMarkerRefs.current.length) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries.length !== 1) return;

      const entry = entries[0];

      const markerIndex = Number(entry.target.getAttribute('data-index'));
      const articleIndex = entry.boundingClientRect.y > 0 ? markerIndex - 1 : markerIndex;
      const isOriginalArticle = articleIndex < 0;
      const { publishedUrl, analyticsData } = articles[articleIndex]?.data ?? {};

      const newUrl = isOriginalArticle ? originalUrl : publishedUrl + '?second_article=true';

      const currentUrl = window.location.pathname + window.location.search;

      if (currentUrl === newUrl) return;

      const updateAnalyticsParams: Parameters<typeof updateTrackingData> = isOriginalArticle
        ? [pageData.data.analyticsData, false]
        : [analyticsData, true];

      updateTrackingData(...updateAnalyticsParams);

      window.history.pushState(null, '', newUrl);

      if (!isOriginalArticle && !registeredArticleIndexes.includes(articleIndex)) {
        registerPageView();
        setRegisteredArticleIndexes((prev) => [...prev, articleIndex]);
      }
    });

    articleMarkerRefs.current.forEach((articleMarkerRef) => observer.observe(articleMarkerRef as Element));

    return () => observer.disconnect();
  }, [articles, pageData.data, registeredArticleIndexes, originalUrl]);

  return (
    <>
      {articles.map((article, index) => (
        <Fragment key={index}>
          <div
            data-index={index}
            ref={(el: HTMLDivElement) => {
              articleMarkerRefs.current[index] = el;
            }}
            className="relative bottom-[100vh]"
          />
          <ScrollArticleLabel content="Nästa artikel" />
          {renderPostTypeComponent(article)}
        </Fragment>
      ))}

      {isLoading && <Spinner />}
      <div ref={markerRef} className="relative bottom-[400vh]" />
    </>
  );
};
