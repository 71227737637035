
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/(labrador)/[device]/article/[[...params]]",
      function () {
        return require("private-next-pages/(labrador)/[device]/article/[[...params]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/(labrador)/[device]/article/[[...params]]"])
      });
    }
  